@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Noto+Sans+SC&family=Noto+Sans&family=Roboto+Condensed&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&family=Noto+Sans+SC:wght@300&family=Noto+Sans:wght@300&display=swap'); */

.container-bg:after {
  content: "";
  position: fixed;
  /* stretch a fixed position to the whole screen */
  top: 0;
  height: 120vh;
  /* fix for mobile browser address bar appearing disappearing */
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  /* needed to keep in the background */
  background: url(./images/background_vertical.jpg) center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: scroll;
}

.toggle-bg:after {
  content: '';
  @apply absolute top-0.5 left-0.5 bg-little-pot-gold border border-little-pot-gold rounded-full h-2.5 w-2.5 transition shadow-sm;
}

input:checked+.toggle-bg:after {
  transform: translateX(0.63rem);
}

/* input:checked+.toggle-bg {
  @apply bg-blue-600 border-blue-600;
} */

/* .container-bg {
  background-image: url(./images/background_vertical.jpg);
  position: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150%;
  background-attachment: scroll;
  height: 120%;
  width: 120%;
  top: 0;
  left: 0;
  z-index: -1;
} */

/* html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */